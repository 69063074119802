<template>
  <div class="timerly-page">
    <!-- Hero -->
    <section class="flex w-full bg-white px-4 text-theme-dark py-24">
      <div class="m-auto grid grid-cols-1 md:grid-cols-2 gap-4 max-w-screen-xl">
        <div class="flex px-2 md:px-20">
          <div class="m-auto text-center md:text-left">
            <h2 class="text-base font-medium text-timerly-blue">TIMERLY</h2>
            <h1 class="text-4xl md:text-6xl font-bold">Develop new habits easily</h1>
          </div>
        </div>
        <div class="pt-12 md:pt-0 flex fadeUp" :class="{'show':showImages}">
          <img alt="timerly main" class="m-auto" style="max-height: 40rem" src="@/assets/portfolio/timerly/habits-coloured.webp">
        </div>
      </div>
    </section>
    <!-- IDEA -->
    <section class="flex w-full py-20 px-4 text-white">
      <div class="grid grid-cols-1 gap-6 max-w-screen-xl m-auto">
        <h3 class="text-center text-5xl md:text-6xl font-bold">The idea</h3>
        <span class="leading-7 text-center text-xl py-8 max-w-screen-md">In order to develop new habits and maintain them over time or even just to maintain current ones, they must be measured correctly. <br>
          Hence the idea of an application that allows you to easily track the achievement of daily goals.</span>
      </div>
    </section>
    <!-- SCREENSHOT -->
    <section class="flex w-full py-20 bg-white px-4 text-theme-dark">
      <div class="grid grid-cols-1 gap-6 max-w-screen-xl m-auto">
        <div class="text-center grid grid-cols-1 gap-4">
          <h3 class="text-5xl md:text-6xl font-bold">The Application</h3>
        </div>
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6 pt-10">
          <img alt="timerly main" src="@/assets/portfolio/timerly/home.webp">
          <img alt="timerly main" src="@/assets/portfolio/timerly/stats.webp">
          <img alt="timerly main" src="@/assets/portfolio/timerly/settings.webp">
          <img alt="timerly main" src="@/assets/portfolio/timerly/new-habit.webp">
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {onMounted, ref} from "vue";

export default {
  name: "timerly",
  setup() {

    const showImages = ref(false)

    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

    onMounted(() => {
      setTimeout(function () {
        showImages.value = true;
      }, 100);
    })

    return {
      showImages
    }
  }
}
</script>

<style lang="scss" scoped>
.teal-bg {
  background-color: #f2feff;
}
</style>